<div class="page-layout blank" fusePerfectScrollbar>

    <div class="p-8 green-500-bg text-center" style="color: white;">
        Basic Needs services are FREE for everyone to use.  Donors are responsible for all USDC specific network transaction fees.  All transactions are FINAL.
    </div>  
    <div class="p-64" fxLayout="row" fxLayoutAlign="center center">
        <app-transfer></app-transfer>
    </div>
    <div class="text-center">
        &copy; 2023 Basic Needs LLC
    </div>
</div>
