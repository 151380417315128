import { Injectable } from '@angular/core';
import {keys} from 'lodash';
import {BehaviorSubject} from 'rxjs';
const Web3 = require('web3');

declare let require: any;
declare let window: any;
const tokenAbi = require('../../../truffle/BasicNeedsContract.json');
const basicNeedsTokenAbi = require('../../../truffle/BasicNeedsToken.json');
const usdcTokenAbi = require('../../../truffle/USDC.json');
const erc20Abi = require('../../../truffle/erc20.json');


@Injectable({
  providedIn: 'root'
})
export class TransferService {
    private account: any = null;
    private web3: any;
    private networkName = '';

    private networkId = '';


   private networkNames = {
    '0x1': 'Ethereum',
    '0x89': 'Polygon'
   }

    private usdcAddresses = {
      '0x1': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',   // USDC on ethereum mainnet
      '0x89': '0x2791bca1f2de4661ed88a30c99a7a9449aa84174' // USDC on polygon mainnet
    };

    private blockscanUrls = {
      '0x1': 'https://etherscan.io', 
      '0x89': 'https://polygonscan.com'
    };

    accountSubject = new BehaviorSubject('');



    constructor() {
      this.initialize();
    }

    private async initialize() {
      const { ethereum } = window;

      if (!Boolean(ethereum && ethereum.isMetaMask)) {
        alert('Non-Ethereum browser detected. Install MetaMask.');
      } else {
        window.web3 = new Web3(window.ethereum);

        const accounts = await ethereum.request({ method: 'eth_accounts' });
        console.log('accounts');
        console.log(accounts);
      }
    }

    public async connectToWallet() {
      const { ethereum } = window;

      try {
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        await ethereum.request({ method: 'eth_requestAccounts' });
        this.networkId = await ethereum.request({method: 'eth_chainId'})
      } catch (error) {
        console.error(error);
      }
    }

    public clearMM(): void {
        // window.web3 = 'undefined';
        // this.setupMM();
        // console.log(window.ethereum);
        window.ethereum.enable();
    }

    public getNetworkName(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.networkName === ''){
                this.networkName = this.networkNames[this.networkId];
                resolve(this.networkName);
            } else {
                resolve(this.networkName);
            }
        });
    }

    public async getAccount(): Promise<any> {
        console.log('getAccount');
        if (this.account == null) {
          this.account = await new Promise((resolve, reject) => {
            console.log(window.web3.eth)
            window.web3.eth.getAccounts().then((accounts: string[]) => {
              console.log(accounts)
              if (accounts.length > 0) {
                this.account = accounts[0];
                this.accountSubject.next(this.account);
                resolve(this.account);
              } else {
                alert('Could not connect to MetaMask');
                this.account = '';
                this.accountSubject.next('');
                reject('No accounts found.');
              }
            });
          }) as Promise<any>;
        }
        return Promise.resolve(this.account);
    }

    public async getUserBalance(): Promise<any> {
        const account = await this.getAccount();
        console.log('transfer.service :: getUserBalance :: account');
        console.log(account);
        return new Promise((resolve, reject) => {
            window.web3.eth.getBalance(account, function(err, balance) {
                console.log('transfer.service :: getUserBalance :: getBalance');
                console.log(balance);
                if (!err) {
                    const retVal = {
                        account: account,
                        balance: window.web3.utils.fromWei(balance)
                    };
                    console.log('transfer.service :: getUserBalance :: getBalance :: retVal');
                    console.log(retVal);
                    resolve(retVal);
                } else {
                    reject({account: 'error', balance: 0});
                }
            });
        }) as Promise<any>;
    }

    public async getBasicNeedsBalance(): Promise<any> {
        const account = await this.getAccount();

        const usdcContract = new window.web3.eth.Contract(erc20Abi, this.usdcAddresses[this.networkId]);
        console.log(usdcContract);

        return new Promise((resolve, reject) => {
            console.log('about to call balanceOf');
            console.log(usdcContract.methods);
            // usdcContract.methods.balanceOf(account).then((balance) => {
            usdcContract.methods.balanceOf(account).call().then(balance => {
                const result = balance / 1000000;
                console.log(String(result));
                resolve(String(result));
            });
        });
    }


    public async contractInfo(contractAddress) {
        // const amount = await window.web3.eth.getBalance(contractAddress)
        console.log(contractAddress);
        const contract = new window.web3.eth.Contract(tokenAbi.abi, contractAddress);

        console.log('lets do this');

        // const totalShares = await contract.methods.totalShares().call().then(console.log);
        await contract.methods.totalShares().call().then(console.log);
        // const released = await contract.methods.totalReleased().call();
        // console.log(totalShares, released);
        // const wallets = [
        //     await contract.methods.payee.call(0),
        //     await contract.methods.payee.call(1)
        // ];
        // const shares = [
        //     {
        //         wallet: wallets[0],
        //         shares: String(await contract.methods.shares.call(wallets[0])),
        //         released: window.web3.utils.fromWei(await contract.methods.released.call(wallets[0]))
        //     },
        //     {
        //         wallet: wallets[1],
        //         shares: String(await contract.methods.shares.call(wallets[1])),
        //         released: window.web3.utils.fromWei(await contract.methods.released.call(wallets[1]))
        //     }
        // ];
        // return {
        //     balance: window.web3.utils.fromWei(1000000000),
        //     totalShares: String(totalShares),
        //     shares,
        //     released: window.web3.utils.fromWei(released),
        //     wallets
        // };
    }

    public async disperseFundsToWallet(walletAddress): Promise<any> {
        const that = this;
        const account = await this.getAccount();
        return new Promise((resolve, reject) => {
            const contract = require('@truffle/contract');
            const transferContract = contract(tokenAbi);
            transferContract.setProvider(that.web3);

            transferContract.deployed().then(instance => {
                instance.release.sendTransaction(walletAddress, {from: account}).then((r) => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            });
        });
    }

    public async transferEther(value): Promise<any> {
        const account = await this.getAccount();

        const usdcContract = new window.web3.eth.Contract(erc20Abi, this.usdcAddresses[this.networkId]);
        console.log(usdcContract);

        return new Promise((resolve, reject) => {
            console.log('about to call balanceOf');
            console.log(usdcContract.methods);
            // usdcContract.methods.balanceOf(account).then((balance) => {
            const usdcValue = Number(value.amount) * 1000000;

            usdcContract.methods.transfer(value.transferAddress, usdcValue).send({from: account}).then(txn => {
             
              console.log(txn);
              resolve({
                transactionHash: txn.transactionHash,
                blockScanUrl: this.blockscanUrls[this.networkId]
              });
            }).catch(err => {
              reject(err?.message ?? 'Error')
            });
        });
    }
}
