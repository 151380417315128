export interface TableData {
    orgName: string;
    programs: {
        programName: string;
        c3ID: string;
        programWallet: string;
        website: string;
        bio: string;
        value: string;
    }[];
}

export const tableData: TableData[] = [
    {
        orgName: 'Easterseals GC',
        programs: [
            {
                programName: 'Disability Program',
                c3ID: '31-0873433',
                programWallet: '0x846485d5FC6E0512Ffce8c68A664efe8346B262D',
                website: 'https://www.eastersealsredwood.org/disability-services/',
                bio: 'At Easterseals Redwood we celebrate children and adults with disabilities by providing countless opportunities to learn and grow.',
                value: '0x846485d5FC6E0512Ffce8c68A664efe8346B262D'
            },
            {
                programName: 'Military Program',
                c3ID: '31-0873433',
                programWallet: '0xaBE73fe6f3ED72Ba0d5b48b3446a23bDBE7F3501',
                website: 'https://www.eastersealsredwood.org/military-veteran-services/',
                bio: 'Easterseals Redwood Military & Veteran Services helps bridge the military and civilian communities.',
                value: '0xaBE73fe6f3ED72Ba0d5b48b3446a23bDBE7F3501'
            }
        ]
    },
    {
        orgName: 'CourageMKE',
        programs: [
            {
                programName: 'MKE Homeless Program',
                c3ID: '81-0768966',
                programWallet: '0x5506a7a57c5efe360FF9aCB1e794Cf7E7403A8a1',
                website: 'https://www.couragemke.org/',
                bio: "Founded in 2015, Courage Initiative Ltd. provides essential housing and support to LGBTQ+ youth facing displacement or homelessness. In 2019, Courage established Wisconsin's first group home for queer youth, pioneering unique, trauma-informed care programs. Expanding their impact, they're launching a second site for LGBTQ+ individuals aged 18-24, offering financial coaching, case management, and life skills training. Beyond housing, Courage supports community initiatives like GSA, scholarships, hygiene kit donations, and annual holiday drives. Their work addresses the critical needs of the 40% of homeless youth who identify as LGBTQ+, combating long-term homelessness and associated challenges.",
                value: '0x5506a7a57c5efe360FF9aCB1e794Cf7E7403A8a1'
            }
        ]
    },
    {
        orgName: 'Doggie Rescue',
        programs: [
            {
                programName: 'Save Fur Kids',
                c3ID: 'No. R251000024',
                programWallet: '0x38A4B4126f3Da30c4c093dfD5E800034dDF490b5',
                website: 'https://doggierescue.com/',
                bio: 'We are a NO-kill Dog and Cat rescue shelter saving our beloved furry friends from pounds so that we can care for, rehabilitate and find loving Forever Homes for them!',
                value: '0x38A4B4126f3Da30c4c093dfD5E800034dDF490b5'
            }
        ]
    }
    
];
