<mat-card
    class="heading"
    *ngIf="connected"
>
    <mat-card-title>BASIC NEEDS DONATION PORTAL</mat-card-title>
</mat-card>

<form
    [formGroup]="userForm"
    (ngSubmit)="submitForm()"
    novalidate
    autocomplete="off"
    *ngIf="connected; else elseBlock"
>
    <div fxFlex="500px">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-subtitle>Kindly select the Charity Program you wish to donate to</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div
                    class="mb-32"
                    *ngIf="!loadingBalance"
                >
                    <div>You are connected to the {{networkName | titlecase }} Network</div>
                    <div>Your USDC Balance: {{user.needsBalance | number}}</div>
                </div>
                <div *ngIf="loadingBalance">
                    Loading your USDC balance...
                </div>
                <div
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="pageStatus === 'SENDING'"
                >
                    <div>
                        Your donation is being sent to your charity and written to the blockchain. Please don't refresh
                        your screen, this can take a minute or two.
                    </div>
                    <div>
                        <img src="assets/images/logos/waiting.gif">
                    </div>
                </div>

                <div *ngIf="pageStatus === 'SUCCESS'">
                    <h2>Thank you!</h2>

                    <p>Your donation has been sent successfully</p>
                    <div>Transaction: {{transactionResult.transactionHash}}</div>
                    <div><a [href]="transactionResult.blockScanUrl + '/tx/' + transactionResult.transactionHash">Click Here to View on Etherscan.
                            Keep Txn# for Tax Purposes!</a></div>
                </div>

                <div
                    class="container"
                    *ngIf="pageStatus === '' && !loadingBalance"
                >
                    <div class="transfer-container">
                        <div fxLayout="column">
                            <mat-form-field>
                                <mat-label>
                                    Select Organization
                                </mat-label>
                                <mat-select
                                    formControlName="org"
                                    (ngModelChange)="onOrgSelected($event)"
                                >
                                    <mat-option
                                        *ngFor="let data of tableData"
                                        [value]="data.orgName"
                                    >
                                        {{data.orgName}}
                                    </mat-option>
                                </mat-select>

                                <!-- <input
                                    matInput
                                    [matMenuTriggerFor]="orgNameMenu"
                                    formControlName="transferAddress"
                                > -->

                                <!-- <mat-menu
                                    #orgNameMenu="matMenu"
                                    yPosition="below"
                                >
                                    <button
                                        *ngFor="let data of tableData"
                                        mat-menu-item
                                        [matMenuTriggerFor]="getProgramMenu(data.orgName)"
                                    >{{ data.orgName }}</button>
                                </mat-menu> -->

                                <!-- <mat-menu
                                    #programMenu="matMenu"
                                    yPosition="below"
                                    *ngFor="let org of tableData"
                                >
                                    <ng-container *ngFor="let program of org.programs">
                                        <button mat-menu-item>
                                            {{ program.programName }}
                                        </button>
                                    </ng-container>
                                </mat-menu> -->
                            </mat-form-field>
                            <mat-form-field *ngIf="selectedOrg">
                                <mat-label>
                                    Select Program
                                </mat-label>
                                <mat-select
                                    formControlName="transferAddress"
                                    (ngModelChange)="onOptionSelected($event)"
                                >
                                    <mat-option
                                        *ngFor="let program of selectedOrg.programs"
                                        [value]="program.value"
                                        (click)="onOptionSelected(program.programName)"
                                    >
                                        {{program.programName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    [disabled]="networkName !== 'MainNet'"
                                    matInput
                                    placeholder="Donation Amount in USDC"
                                    name="amount"
                                    formControlName="amount"
                                    required
                                >
                                <mat-error *ngFor="let validation of accountValidationMessages.amount">
                                    <mat-error
                                        *ngIf="userForm.get('amount').hasError(validation.type) && (userForm.get('amount').dirty || userForm.get('amount').touched)"
                                    >{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <mat-card
                    *ngIf="error"
                    class="error-card"
                >{{error}}</mat-card>
            </mat-card-content>
            <mat-card-actions>
                <button
                    [disabled]="pageStatus !== ''"
                    mat-raised-button
                    *ngIf="pageStatus !== 'SUCCESS'"
                    type="submit"
                >
                    DONATE NOW
                </button>
                <button
                    mat-button
                    *ngIf="pageStatus === 'SUCCESS'"
                    (click)="pageStatus = ''"
                >DONE</button>
            </mat-card-actions>
        </mat-card>
    </div>
</form>

<mat-card
    *ngIf="selectedProgram"
    class="example-card card"
>
    <mat-card-header>
        <mat-card-title>{{ selectedOrg.orgName }}</mat-card-title>
        <mat-card-subtitle>{{ selectedProgram.programName }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p class="card-text">{{ selectedProgram.c3ID }}</p>
        <p class="card-text">{{ selectedProgram.programWallet }}</p>
        <a
            href={{selectedProgram.website}}
            class="card-text"
        >
            {{ selectedProgram.website }}
        </a>
        <p class="card-text">Bio: {{ selectedProgram.bio }}</p>
    </mat-card-content>
</mat-card>

<ng-template #elseBlock>
    <div fxFlex="200px">
        <mat-card class="connect-card">
            <button
                mat-button
                (click)="connectToWallet()"
            >
                Connect to MetaMask
            </button>
        </mat-card>
    </div>
</ng-template>
