import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TransferService } from '../transfer.service';
import { tableData, TableData } from './details';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss'],
    providers: [TransferService]
})
export class TransferComponent implements OnInit {

    formSubmitted = false;
    userForm: FormGroup;
    user: any;
    loading = false;
    transactionResult: any;
    pageStatus = '';
    loadingBalance = true;
    networkName = '';
    transferAddress = '';
    connected = false;
    error = '';

    selectedProgram: any;
    tableData: TableData[] = tableData;
    selectedOrg: TableData;

    constructor(private fb: FormBuilder, private transferService: TransferService) { }

    ngOnInit() {
        console.log(tableData)

        this.formSubmitted = false;
        // 0x9aed100e439a772df55477dcbedc9850df8d42a2
        // 0xd3538FE8bEC36648a25F76D7F021F5D9d90E0185
        this.user = {
            address: '',
            transferAddress: '',
            balance: '',
            amount: '',
            remarks: 'Basic Needs Donation'
        };
    }

    onOrgSelected(event: any) {
        this.selectedOrg = this.tableData.find((option) => option.orgName === event);
        console.log('selectedOrg', this.selectedOrg);
    }

    onOptionSelected(progName: string) {
        console.log('onOptionSelected', progName);
        this.selectedProgram = this.selectedOrg.programs.find((option) => option.programName === progName);
        console.log('selectedProgram', this.selectedProgram);
    }

    // getProgramMenu(orgName: string): MatMenu {
    //     const org = this.tableData.find((item) => item.orgName === orgName);
    //     console.log('org', org)         
    //     console.log(this.programMenus);
    //     if (org) {
    //         return this.programMenus[org.orgName];
    //     }
    //     return null;
    // }

    accountValidationMessages = {
        transferAddress: [
            { type: 'required', message: 'Transfer Address is required' },
            { type: 'minLength', message: 'Transfer Address must be 42 characters long' },
            { type: 'maxLength', message: 'Transfer Address must be 42 characters long' }
        ],
        amount: [
            { type: 'required', message: 'Amount is required' },
            { type: 'pattern', message: 'Amount must be a positive number' }
        ],
        remarks: [
            { type: 'required', message: 'Remarks are required' }
        ]
    };

    async connectToWallet() {
        await this.transferService.connectToWallet();

        this.getAccountAndBalance();
        this.createForms();
        this.connected = true;
    }

    createForms() {
        this.userForm = this.fb.group({
            org: new FormControl(this.user.transferAddress, Validators.compose([
                Validators.required
            ])),
            transferAddress: new FormControl(this.user.transferAddress, Validators.compose([
                Validators.required
            ])),
            amount: new FormControl(this.user.amount, Validators.compose([
                Validators.required,
                Validators.pattern('^[+]?([.]\\d+|\\d+[.]?\\d*)$')
            ])),
            remarks: new FormControl(this.user.remarks, Validators.compose([
                Validators.required
            ]))
        });
    }

    getAccountAndBalance = () => {
        this.loadingBalance = true;
        const that = this;


        this.transferService.getNetworkName().then((result) => {
            console.log(result)
        } );

        this.transferService.getUserBalance().
            then((retAccount: any) => {
                that.user.address = retAccount.account;
                that.user.balance = retAccount.balance;
                console.log('transfer.components :: getAccountAndBalance :: that.user');
                console.log(that.user);
                this.transferService.getBasicNeedsBalance().then((result) => {
                    console.log(`balance of NEEDS: ${result}`);
                    that.user.needsBalance = result;

                    // now get the network that MM is attached to
                    this.transferService.getNetworkName().then(network => {
                        this.networkName = network;
                        console.log('network name', network);
                        this.loadingBalance = false;
                    });
                });
            }).catch(error => {
                console.log(error);
                this.loadingBalance = false;
            });
    }

    submitForm() {
        if (this.userForm.invalid) {
            this.error = 'Form is invalid';
            return;
        } else {
            this.pageStatus = 'SENDING';
            this.transferService.transferEther(this.userForm.value).then(result => {
                console.log(result)
                this.transactionResult = result;
                this.getAccountAndBalance();
                this.pageStatus = 'SUCCESS';
            }).catch(result => {
                this.error = result;
                this.pageStatus = '';
            });
        }
    }

    resetMM() {
        this.transferService.clearMM();
    }
}
